import React, { useEffect } from 'react';
import './Thumbnail.css';

interface ThumbnailProps {
  src: string;
  alt: string;
  className?: string;
  id?: string;
  index: number;
  isOpen: boolean;
  totalImages: number;
  onNavigate: (newIndex: number) => void;
  onClose: () => void;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ src, alt, className, id, index, isOpen, totalImages, onNavigate, onClose }) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isOpen) return;

    if (e.key === 'ArrowRight') {
      onNavigate((index + 1) % totalImages); // Navigate to next image
    } else if (e.key === 'ArrowLeft') {
      onNavigate((index - 1 + totalImages) % totalImages); // Navigate to previous image
    } else if (e.key === 'Escape') {
      onClose(); // Close modal
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <>
      <div className={`thumbnail ${className}`} onClick={() => onNavigate(index)}>
        <img src={src} alt={alt} id={id} />
      </div>
      {isOpen && (
        <div className="modal" onClick={onClose}>
          <button
            className="arrow-button left"
            onClick={(e) => {
              e.stopPropagation();
              onNavigate((index - 1 + totalImages) % totalImages);
            }}
          >
            ←
          </button>
          <img src={src} alt={alt} className="modal-image" />
          <button
            className="arrow-button right"
            onClick={(e) => {
              e.stopPropagation();
              onNavigate((index + 1) % totalImages);
            }}
          >
            →
          </button>
        </div>
      )}
    </>
  );
};

export default Thumbnail;
