import React, { useState } from 'react';
import image from '../../images/_MG_4413.jpg';
import Hero from '../../components/hero/Hero';
import Thumbnail from '../../components/thumbnail/Thumbnail';
import './Landing.css';

// Array to store image data
const imageList = [
  { src: "../../images/_MG_4456.jpg", className: "span-2", id: "" },
  { src: "../../images/96a04337-3d79-4517-9052-40156c3b604f-1_all_7453.jpg", className: "span-4", id: "vigdis" },
  { src: "../../images/1000005226.jpg", className: "span-3", id: "" },
  { src: "../../images/1000005249.jpg", className: "", id: "" },
  { src: "../../images/1000009975.jpg", className: "span-4", id: "" },
  { src: "../../images/20230215131000_IMG_1193.jpg", className: "", id: "" },
  { src: "../../images/20240707164350_IMG_1668.jpg", className: "span-2-row", id: "" },
  { src: "../../images/16691296257454644352.jpg", className: "span-3-col", id: "" },
  { src: "../../images/16783025066292206244.jpg", className: "span-2-col", id: "" },
  { src: "../../images/3572292384813520728.jpg", className: "", id: "" },
  { src: "../../images/5060721399154276715.jpg", className: "span-2-row", id: "" },
  { src: "../../images/9607104211616854449.jpg", className: "", id: "" },
  { src: "../../images/IMG_0069.jpg", className: "span-3", id: "" },
  { src: "../../images/IMG_1059.jpg", className: "span-4", id: "" },
  { src: "../../images/IMG_1110.jpg", className: "span-3-col", id: "" },
  { src: "../../images/IMG_1112.jpg", className: "span-2-row", id: "" },
  { src: "../../images/IMG_1114.jpg", className: "", id: "" },
  { src: "../../images/IMG_1135.jpg", className: "span-4", id: "" },
  { src: "../../images/IMG_1155.jpg", className: "span-2", id: "" },
  { src: "../../images/IMG_1160.jpg", className: "", id: "" },
  { src: "../../images/IMG_1777.jpg", className: "span-4-row", id: "" },
  { src: "../../images/IMG_1783.jpg", className: "span-3-col", id: "" },
  { src: "../../images/IMG_1815.jpg", className: "span-3", id: "" },
  { src: "../../images/IMG_1832.jpg", className: "", id: "" },
  { src: "../../images/IMG_3283.jpg", className: "span-4", id: "" },
  { src: "../../images/IMG_3824.jpg", className: "span-2", id: "" },
  { src: "../../images/IMG_3838.jpg", className: "", id: "" },
  { src: "../../images/IMG_3866.jpg", className: "span-3-col", id: "" },
  { src: "../../images/IMG_4291.jpg", className: "span-2-row", id: "" },
  { src: "../../images/received_1076196929429186.jpeg", className: "", id: "" },
  { src: "../../images/received_2676751849097919.jpeg", className: "span-4-row", id: "" },
  { src: "../../images/received_2810694955705147.jpeg", className: "span-3", id: "" },
]; 

function Landing() {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const handleNavigate = (newIndex: number) => {
    setCurrentIndex(newIndex);
  };

  const handleClose = () => {
    setCurrentIndex(null);
  };

  return (
    <div className='landing'>
      <Hero
        image={image}
        headline="Gallery"
        subtext="Explore stunning images"
      />
      <h1>Johnni Riis Jensen</h1>
      <div className="description">
        <p>Click on the images to enlarge them for the best quality and experience.</p>
        <div className="arrow-animation">
          <span className="arrow">↓</span>
        </div>
      </div>
      <div className="image-gallery">
        {imageList.map((img, index) => (
          <Thumbnail
            key={index}
            src={img.src}
            alt={`Gallery Image ${index + 1}`}
            className={img.className}
            id={img.id}
            index={index}
            isOpen={currentIndex === index}
            totalImages={imageList.length}
            onNavigate={handleNavigate}
            onClose={handleClose}
          />
        ))}
      </div>
    </div>
  );
}

export default Landing;
